
import { defineComponent } from "vue";
import { ElLoading } from "element-plus";
import helpers from "@/helpers/global";
import _ from "lodash";

export default defineComponent({
  name: "CreateAccount",
  created() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    this.toolKey = urlSearchParams.get("tool") || "";

    if (localStorage.getItem("userData")) {
      this.$router.replace(this.toolKey ? `/${this.toolKey}` : "/");
      return;
    }

    this.$store.dispatch("auth/initSignupFormDetails", null, { root: true });
    const signupFormDetails = this.$store.getters["auth/signupFormDetails"];
    if (_.has(signupFormDetails, "email")) {
      this.email = _.get(signupFormDetails, "email");
    }

    this.$store.dispatch(
      "tracking/save",
      {
        ns: "signup.email.view",
        group: "signup",
        email: this.email || "",
      },
      { root: true }
    );

    this.$store.dispatch("forceReady", null, { root: true });
  },
  watch: {
    error(value) {
      console.error(value);
    },
    userLogin(value) {
      if (value) {
        this.$router.push("/");
      }
    },
    loadingStatus(value) {
      const loading = ElLoading.service({
        lock: true,
        text: "Login..",
        background: "#ffffff90",
      });
      if (!value) {
        loading.close();
      }
    },
    email(value) {
      this.checkingEmailStatus(value);
    },
  },
  computed: {
    userLogin() {
      return this.$store.getters.getLoginStatus;
    },
  },
  data() {
    return {
      email: "",
      emailWarningText: "",
      validateEmail: false,
      toolKey: "",
    };
  },
  mounted() {
    if (this.userLogin) {
      this.$router.push("/");
    }
    this.setLangOnInit();
  },
  methods: {
    setLangOnInit() {
      const lang = this.$router.currentRoute.value.meta.lang || "en";
      if (lang && lang == "TH") {
        (this as any).$i18n.setLocale("th");
        localStorage.setItem("lang", (lang as string).toUpperCase());
      } else {
        (this as any).$i18n.setLocale("en");
        localStorage.setItem("lang", (lang as string).toUpperCase());
      }
    },
    checkingEmailStatus(email: string) {
      const emailValid = email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (this.email.length > 5 && emailValid) {
        this.validateEmail = true;
        // this.emailWarningText = "";
      } else if (this.email.length > 5 && !emailValid) {
        this.validateEmail = false;
        // this.emailWarningText = "This is not a valid email";
      } else {
        this.validateEmail = false;
        // this.emailWarningText = "";
      }
    },
    handleClickAuthProvider(provider: string) {
      this.signinByProvider(provider);
    },
    signinByProvider(provider: string) {
      const authData = {
        email: null,
        provider: provider,
      };
      this.$store.dispatch("auth/login", authData, { root: true });
    },
    loginWithGoogle() {
      this.$store.dispatch("userLoginWidthGoogle", true);
    },
    async login() {
      if (this.validateEmail) {
        await this.$store.dispatch(
          "tracking/save",
          {
            ns: "signup.email",
            group: "signup",
            email: this.email,
          },
          { root: true }
        );

        await this.$store.dispatch(
          "auth/requestSignupWeb",
          { email: this.email },
          { root: true }
        );

        if (this.$store.getters["auth/error"]) {
          this.emailWarningText = this.$store.getters["auth/error"];
          this.validateEmail = false;

          await this.$store.dispatch(
            "tracking/save",
            {
              ns: "signup.email.error",
              group: "signup",
              email: this.email,
              message: this.emailWarningText,
            },
            { root: true }
          );

          return;
        }

        this.$store.dispatch(
          "auth/setSignupEmail",
          { email: this.email },
          { root: true }
        );

        await this.$store.dispatch(
          "tracking/save",
          {
            ns: "signup.email.success",
            group: "signup",
            email: this.email,
          },
          { root: true }
        );

        const referralCode = helpers.getReferralCode();
        if (referralCode) {
          this.$router.push("/signup/verify?" + referralCode);
        } else {
          const query: any = {};
          if (this.toolKey) {
            query.tool = this.toolKey;
          }
          query.email = this.email;
          query.flow = "signup";
          this.$router.push({
            name: "SignupVerify",
            query,
          });
        }
      }
    },
  },
});
